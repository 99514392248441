.App {
  text-align: center;
}

.App-header {
  background-color: #eeeee8;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: rgb(49, 49, 49);
  padding: 40px;
}

.imageframe {
  width: 98vw;
  height: 90vh;
  text-align: center;
  display: flex;
  justify-content: center;
}

.image {
  display: flex;
  flex-direction: column;
}

.imageframe img {
  object-fit: contain;
  max-width: 90vw;
  max-height: 80vh;

}

h1 {
  font-size: 8rem;
}

h2 {
  font-size: 1.8rem;
  margin-bottom: 0;
}

a {
  font-size: 1.4rem;
}

.App-link {
  color: #61dafb;
}

p {
  font-size: 1.15rem;
  width: 50vw;
  text-align: left;
  line-height: 1.35;
  margin-top: 10px;
  margin-bottom: 0;
}

.caption {
  font-size: 0.9rem;
  font-weight: 300;
}

.caption span {
  font-weight: 600;
}


.backbutton {
  font-size: 1.3rem;
  font-weight: 600;
  padding: 20px;
  color: rgb(49, 49, 49);

}

.backbutton:active,
.backbutton:visited,
.backbutton:hover 
 {
  color: rgb(49, 49, 49);
}

.links a {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.links p {
  font-size: 1.4rem;
  font-weight: 400;
}

.links a {
  font-size: 1.2rem;
}

.links .horisontal {
  display: flex;
}

.horisontal a:first-of-type {
  padding-right: 20px;
}